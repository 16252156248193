<template>
  <div
    class=" flex bg-lightblue flex-col h-screen md:flex-row items-center justify-center mb-3"
  >
    <div class=" w-11/12 md:w-4/12 bg-white  p-6 rounded-lg ">
      <h2 class="font-bold text-lg text-center text-darkblue">
        Reset your password
      </h2>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(ResetPassword)">
          <div class="mb-4">
            <label class="block" for="password">New Password</label>
            <validation-provider rules="required" v-slot="{ errors }">
              <input
                :type="!!showPassword ? 'text' : 'password'"
                id="password"
                v-model="password"
                placeholder="Input your new password"
                class="border-2 w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="mb-4">
            <label class="block" for="confirmPassword">Confirm Password</label>
            <validation-provider rules="required" v-slot="{ errors }">
              <input
                :type="!!showPassword ? 'text' : 'password'"
                id="confirmPassword"
                v-model="confirmPassword"
                placeholder="Confirm your password"
                class="border-2 w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="mb-4">
            <label class="text-xs mr-1" for="showPassword">
              Show password
            </label>
            <input
              v-model="showPassword"
              type="checkbox"
              name=""
              id="showPassword"
            />
          </div>

          <button
            type="submit"
            class="text-white block spinner bg-darkblue hover:bg-black pl-6 pt-2 pb-2 pr-6 ml-auto mr-auto rounded-full"
          >
            <svg
              v-if="loadingSpinner"
              class="inline animate-spin mr-2"
              width="18"
              height="18"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>

            <span>Reset password</span>
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";

  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  extend("required", {
    ...required,
    message: "This field is required",
  });
  export default {
    name: "ForgotPassword",
    props: {},
    components: {
      ValidationProvider,
      ValidationObserver,
    },

    data() {
      return {
        email: "",
        confirmPassword: "",
        showPassword: false,
        loginError: null,
        password: "",
        loadingSpinner: false,
      };
    },
    computed: {
      passwordCheck: function() {
        return this.password === this.confirmPassword;
      },
    },
    methods: {
      async ResetPassword() {
        if (!this.passwordCheck) {
          this.$emit("show-alert", {
            type: "error",
            message: `Your new passwor and confirm password are not the same.
                `,
          });
        }
        this.loadingSpinner = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($resetToken: String!, $password: String!) {
                resetPassword(resetToken: $resetToken, password: $password) {
                  payload
                  classification
                  type
                }
              }
            `,
            // Parameters
            variables: {
              password: this.password,
              resetToken: this.$route.params.resetToken,
            },
          });

          if (!errors && data.resetPassword) {
            this.$emit("show-alert", {
              type: "success",
              message: `Your password has been reset succesffully. You can login with your new password
                `,
            });

            return this.$router.push("/");
          }

          if (!!errors && data == null) {
            this.loadingSpinner = false;

            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error resetting password,check your network or contact your developer`,
          });
          this.loadingSpinner = false;
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error sending resetting password,check your network or contact your developer`,
          });
        }
      },
    },
    mounted() {},
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
